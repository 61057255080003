'use client';

import Image from 'next/image';
import classNames from 'classnames';

import { LinkInternal } from '@/components/Link';

import styles from './Logo.module.scss';

export function Logo() {
  return (
    <LinkInternal variant="unstyled" href="/" className={styles.logoLink} aria-hidden="true">
      <div className={styles.logo}>
        <Image
          className={classNames(styles.logo, styles.logoHorizontal)}
          src="/logo-iofinnet-horizontal.svg"
          alt=""
          fill
        />
        <Image className={classNames(styles.logo, styles.logoSolo)} src="/logo-iofinnet.svg" alt="" fill />
      </div>
    </LinkInternal>
  );
}
