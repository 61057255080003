import { unstable_cache } from 'next/cache';

import { Env } from '@/lib/env';
import { favoriteDefiApps, localDefiApps } from '@/features/dapps';
import { PaginationInfoCustom } from '@/components/Pagination';

export type DappListItem = {
  id: string;
  name: string;
  description: string;
  homepage: string;
  chainIds: string[];
  image_url: {
    sm: string;
    md: string;
    lg: string;
  };
  isActive?: boolean;
};

type DappListResponse = {
  listings: {
    [key: string]: DappListItem;
  };
  total: number;
};

type DappsResponse = {
  dapps: DappListItem[];
  paginationInfo: PaginationInfoCustom;
};

export const DAPPS_ITEMS_PER_PAGE = 9;

export const fetchDapps = unstable_cache(
  async (page: number, entries: number = DAPPS_ITEMS_PER_PAGE, query?: string): Promise<DappsResponse> => {
    // @see {@link https://docs.walletconnect.com/cloud/explorer}
    const url = new URL('https://explorer-api.walletconnect.com/v3/dapps');
    url.searchParams.append('projectId', Env.walletConnect.projectId);
    url.searchParams.append('entries', (page === 1 ? entries - 3 : entries).toString());
    url.searchParams.append('page', page.toString());
    url.searchParams.append('sdks', 'auth_v2');
    if (query) url.searchParams.append('search', query);

    const response = await fetch(url);

    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Failed to fetch dapps: ${response.status} ${response.statusText}`, errorText);
      throw new Error('Failed to fetch dapps');
    }

    const data: DappListResponse = await response.json();

    const totalCount = data.total;
    const totalPages = Math.ceil(totalCount / entries);

    const paginationInfo: PaginationInfoCustom = {
      endCursor: page < totalPages ? (page + 1).toString() : page.toString(),
      startCursor: page > 1 ? page.toString() : '',
      hasNextPage: page < totalPages,
      hasPreviousPage: page > 1,
      totalPages,
    };

    let dapps = Object.values(data.listings);
    if (page === 1) {
      // Applying query if needed
      const filteredApps = query
        ? favoriteDefiApps.filter((app) => app.name.toLowerCase().includes(query.toLowerCase()))
        : favoriteDefiApps;
      dapps = [...filteredApps, ...dapps];
      // Ensure unique dapps
      dapps = [...new Map(dapps.map((app) => [app.name, app])).values()];
    }

    return { dapps, paginationInfo };
  },
);

export const fetchLocalDapps = async (
  page: number,
  entries: number = DAPPS_ITEMS_PER_PAGE,
  query?: string,
): Promise<DappsResponse> => {
  let dapps = localDefiApps.filter((app) => app?.isActive).sort((a, b) => Number(a.id) - Number(b.id));

  if (query) dapps = dapps.filter((app) => app.name.toLowerCase().includes(query.toLowerCase()));

  const start = (page - 1) * entries;
  const end = start + entries;

  const paginatedDapps = dapps.slice(start, end);

  const totalCount = dapps.length;
  const totalPages = Math.ceil(totalCount / entries);

  const paginationInfo: PaginationInfoCustom = {
    endCursor: page < totalPages ? (page + 1).toString() : page.toString(),
    startCursor: page > 1 ? page.toString() : '',
    hasNextPage: page < totalPages,
    hasPreviousPage: page > 1,
    totalPages,
  };

  return { dapps: paginatedDapps, paginationInfo };
};
