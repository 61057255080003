'use client';

import classNames from 'classnames';

import { IconName, Icons } from '@/lib/io-kit/Icons';
import { LinkInternal } from '@/components/Link';
import { usePathname } from '@/lib/navigation';

import styles from './PageLink.module.scss';

type Props = {
  to: React.ComponentPropsWithoutRef<typeof LinkInternal>['href'];
  highlightExclude?: string[] | string;
  iconName: IconName;
  label: string;
  collapseInMdViewports: boolean;
  'data-testid'?: string;
};

export function PageLink(props: Props) {
  const Icon = Icons[props.iconName];
  const excludeList = Array.isArray(props.highlightExclude)
    ? props.highlightExclude
    : props.highlightExclude
    ? [props.highlightExclude]
    : [];

  const pathname = usePathname();
  const isCurrentPage =
    pathname.startsWith(props.to.toString()) && !excludeList.some((exlcude) => pathname.startsWith(exlcude));
  const linkClasses = classNames(styles.navLink, {
    [styles.navLinkActive]: isCurrentPage,
    [styles.collapseInMdViewports]: props.collapseInMdViewports ?? false,
  });

  return (
    <LinkInternal variant="unstyled" href={props.to} className={linkClasses} data-testid={props['data-testid']}>
      <Icon aria-hidden="true" className={styles.navLinkIcon} />
      <div className={styles.navLinkLabelWrapper}>
        <span className={styles.navLinkLabel}>{props.label}</span>
      </div>
    </LinkInternal>
  );
}
