import { EIP155_MAINNET_CHAINS } from '@/lib/wallet-connect';
import { DappListItem } from '@/features/dapps';

export const localDefiApps: DappListItem[] = [
  {
    id: '1',
    name: 'WalletConnect',
    description: 'Connect io.vault to any dApp that supports WalletConnect',
    homepage: 'https://walletconnect.com/',
    chainIds: Object.keys(EIP155_MAINNET_CHAINS),
    image_url: {
      sm: '/dapps/wallet-connect.svg',
      md: '/dapps/wallet-connect.svg',
      lg: '/dapps/wallet-connect.svg',
    },
    isActive: true,
  },
  {
    id: '2',
    name: 'Uniswap',
    description: 'The leading decentralized crypto trading protocol, governed by a global community.',
    homepage: 'https://app.uniswap.org',
    chainIds: ['1', '10', '137', '420', '42161'],
    image_url: {
      sm: '/dapps/uniswap.svg',
      md: '/dapps/uniswap.svg',
      lg: '/dapps/uniswap.svg',
    },
    isActive: true,
  },
  {
    id: '3',
    name: 'Aave',
    description: 'Non-custodial liquidity protocol',
    homepage: 'https://app.aave.com',
    chainIds: ['1', '10', '137', '42161', '43114'],
    image_url: {
      sm: '/dapps/aave.svg',
      md: '/dapps/aave.svg',
      lg: '/dapps/aave.svg',
    },
    isActive: true,
  },
  {
    id: '4',
    name: '1inch',
    description: 'Decentralized exchange aggregator',
    homepage: 'https://app.1inch.io',
    chainIds: ['1', '56', '100', '137'],
    image_url: {
      sm: '/dapps/1inch.svg',
      md: '/dapps/1inch.svg',
      lg: '/dapps/1inch.svg',
    },
    isActive: true,
  },
  {
    id: '5',
    name: 'Lido',
    description: 'Liquid staking solution for Ethereum.',
    homepage: 'https://stake.lido.fi',
    chainIds: ['1'],
    image_url: {
      sm: '/dapps/lido.svg',
      md: '/dapps/lido.svg',
      lg: '/dapps/lido.svg',
    },
    isActive: true,
  },
  {
    id: '6',
    name: 'Stargate',
    description: 'Cross-chain bridge protocol',
    homepage: 'https://stargate.finance',
    chainIds: ['1'],
    image_url: {
      sm: '/dapps/stargate.svg',
      md: '/dapps/stargate.svg',
      lg: '/dapps/stargate.svg',
    },
    isActive: true,
  },
  {
    id: '7',
    name: 'PancakeSwap',
    description: 'Decentralized exchange on Binance Smart Chain',
    homepage: 'https://pancakeswap.finance',
    chainIds: ['1', '5', '56'],
    image_url: {
      sm: '/dapps/pancakeswap.svg',
      md: '/dapps/pancakeswap.svg',
      lg: '/dapps/pancakeswap.svg',
    },
    isActive: true,
  },
  {
    id: '8',
    name: 'Dydx',
    description: 'Decentralized exchange for margin trading',
    homepage: 'https://dydx.exchange',
    chainIds: ['1'],
    image_url: {
      sm: '/dapps/dydx.svg',
      md: '/dapps/dydx.svg',
      lg: '/dapps/dydx.svg',
    },
    isActive: true,
  },
  {
    id: '9',
    name: 'Compound',
    description: 'Algorithmic, autonomous interest rate protocol',
    homepage: 'https://compound.finance',
    chainIds: ['1'],
    image_url: {
      sm: '/dapps/compound.svg',
      md: '/dapps/compound.svg',
      lg: '/dapps/compound.svg',
    },
    isActive: true,
  },
  {
    id: '10',
    name: 'MakerDAO',
    description: 'Decentralized autonomous organization on the Ethereum blockchain',
    homepage: 'https://makerdao.com',
    chainIds: ['1', '5'],
    image_url: {
      sm: '/dapps/makerdao.svg',
      md: '/dapps/makerdao.svg',
      lg: '/dapps/makerdao.svg',
    },
    isActive: true,
  },
  {
    id: '11',
    name: 'SushiSwap',
    description:
      'Be a DeFi Chef with Sushi. Swap, earn, stack yields, lend, borrow, leverage all on one decentralized, community driven platform.',
    homepage: 'https://sushi.com',
    chainIds: ['1', '43113', '56', '42220', '250', '128', '137', '66', '100'],
    image_url: {
      sm: '/dapps/sushiswap.svg',
      md: '/dapps/sushiswap.svg',
      lg: '/dapps/sushiswap.svg',
    },
    isActive: true,
  },
];

export const favoriteDefiApps: DappListItem[] = localDefiApps.filter((app) =>
  ['WalletConnect', 'Uniswap', 'Aave v3'].includes(app.name),
);
