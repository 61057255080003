import { getTranslations } from 'next-intl/server';

import { Icons } from '@/lib/io-kit/Icons';

import styles from './NoDappssFound.module.scss';

export default async function NoDappssFound({ dappName }: { dappName: string }) {
  const t = await getTranslations('Pages.Dapps');
  return (
    <div className={styles.noDapps}>
      <Icons.Dapps className={styles.noDappsIcon} />
      <span>{t('noDappsFound', { dappName })}</span>
    </div>
  );
}
