'use client';

import { useTranslations } from 'next-intl';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { CryptoAddressOrId } from '@/components/CryptoAddressOrId';
import { Jazzicon } from '@/lib/io-kit/Jazzicon';
import { LoadingButton } from '@/lib/io-kit/Button';

import { WalletConnectFormFragment, WalletConnectFormAddressFragment } from '../page-logic';

import styles from './VaultInfoDisplay.module.scss';

type Props = {
  vault: FragmentType<typeof WalletConnectFormFragment>;
  address?: FragmentType<typeof WalletConnectFormAddressFragment>;
  isLoading: boolean;
  onClick: () => void;
};

export function VaultInfoWithAction({ vault, address, isLoading, onClick }: Props) {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  const vaultData = getFragmentData(WalletConnectFormFragment, vault);
  const addressData = address ? getFragmentData(WalletConnectFormAddressFragment, address) : undefined;

  return (
    <div className={styles.vaultInfo}>
      <div className={styles.vaultHeader}>
        <Vault
          data={{ value: vaultData.id, label: vaultData.name, threshold: vaultData.threshold, address: addressData }}
        />
        <LoadingButton variant="dark" onClick={onClick} loading={isLoading}>
          {t('change')}
        </LoadingButton>
      </div>
    </div>
  );
}

function Vault({
  data,
}: {
  data: { value: string; label: string; threshold: number; address?: { addressHash: string } };
}) {
  return (
    <div className={styles.wrapper}>
      <Jazzicon.Vault size="huge" vaultId={data.value} threshold={data.threshold} />
      <div className={styles.info}>
        <div className={styles.vaultName} title={data.label}>
          {data.label}
        </div>
        {data.address && (
          <div className={styles.walletAndBalance}>
            <CryptoAddressOrId value={data.address.addressHash} />
          </div>
        )}
      </div>
    </div>
  );
}
