import cx from 'classnames';
import { Props as SelectProps, OptionProps, components, SingleValueProps } from 'react-select';

import { useFormatter } from '@/lib/intl';
import { Jazzicon } from '@/lib/io-kit/Jazzicon';
import { Select } from '@/lib/io-kit/Select';

import styles from './VaultSelect.module.scss';

export type VaultOption = {
  value: string;
  label: string;
  threshold: number;
  isSelected: boolean;
  address:
    | {
        id: string | undefined;
        addressHash: string | undefined;
        balanceUsd: string | undefined;
        balanceAsCoin: string | undefined;
        assetId: string | undefined;
      }
    | undefined;
};

const { Option, SingleValue } = components;

const CustomOption = ({ ...props }: OptionProps<VaultOption>) => {
  const formatter = useFormatter();
  const { data } = props;

  return (
    <Option {...props} className={styles.optionContainer} data-testid={`vault-select-option-${data.value}`}>
      {Vault({ data, formatter })}
    </Option>
  );
};

const CustomSingleValue = ({ data, children, ...props }: SingleValueProps<VaultOption>) => {
  const formatter = useFormatter();

  return (
    <SingleValue data={data} {...props}>
      {props.selectProps.menuIsOpen ? null : Vault({ data, formatter })}
    </SingleValue>
  );
};

type Props = SelectProps<VaultOption> & {
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  defaultValue?: VaultOption;
};

export function VaultSelect({
  className,
  'data-testid': testId,
  options,
  placeholder = '…',
  openMenuOnFocus = true,
  defaultValue,
  ...props
}: Props) {
  return (
    <Select
      className={className}
      classNames={{
        valueContainer: () => cx(styles.valueContainer),
      }}
      components={{
        Option: (props) => <CustomOption {...props} />,
        SingleValue: (props) => <CustomSingleValue {...props} />,
      }}
      defaultValue={defaultValue}
      tabSelectsValue={false}
      data-testid={testId}
      isMulti={false}
      placeholder={placeholder}
      openMenuOnFocus={openMenuOnFocus}
      options={options}
      isOptionSelected={(option) => option.isSelected}
      {...props}
    />
  );
}

function Vault({ data, formatter }: { data: VaultOption; formatter: any }) {
  return (
    <div className={styles.wrapper}>
      <Jazzicon.Vault size="huge" vaultId={data.value} threshold={data.threshold} />

      <div className={styles.info}>
        <div className={styles.vaultName}>{data.label}</div>
        {data.address ? (
          <div className={styles.walletAndBalance}>
            {formatter.fiat({ value: data.address.balanceUsd, currency: 'USD' })} | {data.address.addressHash}
          </div>
        ) : null}
      </div>
    </div>
  );
}
