import { z } from 'zod';

import { Network } from '@/lib/models';

const isPairingUri = (uri: string): boolean => {
  return uri.startsWith('wc:');
};

export type LoginFormType = z.infer<typeof LoginFormSchema>;
export const LoginFormSchema = z.object({
  vaultId: z.string(),
  wcCode: z.string(),
  source: z.object({
    id: z.string(),
    addressHash: z.string(),
    assetId: z.string(),
    balanceAsCoin: z.string(),
    balanceUsd: z.string(),
  }),
});

export type LoginApiType = z.infer<typeof LoginApiSchema>;
export const LoginApiSchema = z
  .object({})
  .merge(LoginFormSchema)
  .partial()
  // TODO: i18n
  .refine((data) => !!data.wcCode || isPairingUri(`${data?.wcCode}`), {
    path: ['wcCode'],
    message: 'Invalid wcCode URI',
  });

export type UpdateFormType = z.infer<typeof UpdateFormSchema>;
export const UpdateFormSchema = z.object({
  vaultId: z.string(),
  network: z.nativeEnum(Network.Type),
  source: z.object({
    id: z.string(),
    addressHash: z.string(),
    assetId: z.string(),
    balanceAsCoin: z.string(),
    balanceUsd: z.string(),
  }),
});

export type UpdateApiType = z.infer<typeof UpdateApiSchema>;
export const UpdateApiSchema = z.object({}).merge(UpdateFormSchema).partial();
