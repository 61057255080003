'use client';

import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-internal-modules
import { zodResolver } from '@hookform/resolvers/zod';

import { Network } from '@/lib/models';
import * as DappsValidation from '@/lib/validation/dapps';
import { FragmentType, getFragmentData } from '@/lib/gql';

import { WalletConnectFormAddressFragment } from './data-fragment';

export type WalletConnectLoginFormInputs = DappsValidation.WalletConnect.LoginFormType;

type DefaultLoginFormValues = {
  vaultId: WalletConnectLoginFormInputs['vaultId'];
  address?: FragmentType<typeof WalletConnectFormAddressFragment>;
  wcCode: WalletConnectLoginFormInputs['wcCode'];
};

type LoginFormProps = {
  defaultValues: DefaultLoginFormValues;
};

export function useWalletConnectLoginForm({ defaultValues }: LoginFormProps) {
  const { address: defaultAddress, ...rest } = defaultValues;
  const addressData = getFragmentData(WalletConnectFormAddressFragment, defaultAddress);

  const source = addressData
    ? {
        id: addressData.id,
        addressHash: addressData.addressHash,
        assetId: addressData.asset.id,
        balanceAsCoin: addressData.balanceAsCoin,
        balanceUsd: addressData.balanceUsd,
      }
    : undefined;

  return useForm<WalletConnectLoginFormInputs>({
    defaultValues: {
      // May be undefined
      source,
      ...rest,
    },
    resolver: zodResolver(DappsValidation.WalletConnect.LoginApiSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    // Brief delay to show error to account for user input speed on the text fields
    delayError: 300,
  });
}

export type WalletConnectUpdateFormInputs = DappsValidation.WalletConnect.UpdateFormType;

type DefaultUpdateFormValues = {
  vaultId: WalletConnectLoginFormInputs['vaultId'];
  network?: Network.Type;
  address?: FragmentType<typeof WalletConnectFormAddressFragment>;
};

type UpdateFormProps = {
  defaultValues: DefaultUpdateFormValues;
};

export function useWalletConnectUpdateForm({ defaultValues }: UpdateFormProps) {
  const { address: defaultAddress, ...rest } = defaultValues;
  const addressData = getFragmentData(WalletConnectFormAddressFragment, defaultAddress);

  const source = addressData
    ? {
        id: addressData.id,
        addressHash: addressData.addressHash,
        assetId: addressData.asset.id,
        balanceAsCoin: addressData.balanceAsCoin,
        balanceUsd: addressData.balanceUsd,
      }
    : undefined;

  return useForm<WalletConnectUpdateFormInputs>({
    defaultValues: {
      source,
      ...rest,
    },
    resolver: zodResolver(DappsValidation.WalletConnect.UpdateApiSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    delayError: 300,
  });
}

export const FieldIds = {
  Vault: 'wallet-connect-vault',
  Network: 'wallet-connect-network',
  wcCode: 'wallet-connect-qr-code',
};
