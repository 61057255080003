import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';

import { Form } from '@/lib/io-kit/Form';
import { Icons } from '@/lib/io-kit/Icons';
import { useGetClipboard } from '@/lib/clipboard';
import { isPairingUri } from '@/lib/wallet-connect';

import { WalletConnectLoginFormInputs, FieldIds } from '../page-logic';

import styles from './CodeInput.module.scss';

type Props = {
  isDisabled?: boolean;
};
export default function CodeInput({ isDisabled = false }: Props) {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  const { control, getValues, setValue } = useFormContext<WalletConnectLoginFormInputs>();
  const { getClipboard } = useGetClipboard();

  // If there is not a selected source address, the recipient address input should be disabled
  // because the whole form needs to be disabled
  const disabled = getValues('source') === null || getValues('source') === undefined || isDisabled;

  const { field, fieldState } = useController({ name: 'wcCode', defaultValue: '', control, disabled });

  const errorMessage = fieldState.error?.message;
  const hasError = Boolean(errorMessage) && fieldState.isTouched;

  const onPaste = useCallback(async () => {
    const clipboard = await getClipboard();
    if (clipboard && isPairingUri(clipboard)) {
      setValue('wcCode', clipboard);
    }
  }, [getClipboard, setValue]);

  return (
    <Form.Group>
      <Form.Label htmlFor={FieldIds.wcCode}>{t('wcCodeLabel')}</Form.Label>
      <Form.Input
        {...field}
        id={FieldIds.wcCode}
        data-testid="wallet-connect.form.wc-code"
        placeholder={t('wcCode')}
        autoComplete="off"
        type="text"
        required
        error={hasError}
        disabled={disabled}
        iconAfter={<Icons.Copy onClick={onPaste} className={styles.copyIcon} />}
      />
      <Form.HelperText>{t('wcCodeHelper')}</Form.HelperText>
      {hasError ? <Form.ErrorText>{errorMessage}</Form.ErrorText> : null}
    </Form.Group>
  );
}
