import { useTranslations } from 'next-intl';

import { showDismissableAlertToast } from '@/components/Toasts';

const isClipboardSupported = (): boolean => {
  // 'clipboard-read' and `readText` are not supported by Firefox
  // @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/readText#browser_compatibility}
  return navigator.userAgent.includes('Firefox');
};

export function useGetClipboard() {
  const t = useTranslations('Toasts.ClipboardToasts');

  const isClipboardGranted = async (): Promise<boolean> => {
    if (isClipboardSupported()) {
      return false;
    }

    let isGranted = false;

    try {
      // @ts-expect-error navigator permissions types don't include clipboard
      const permission = await navigator.permissions.query({ name: 'clipboard-read' });
      isGranted = permission.state === 'granted';
    } catch {
      showDismissableAlertToast(t('PermissionError.title'), t('PermissionError.description'), 'error');
    }

    return isGranted;
  };

  const getClipboard = async (): Promise<string> => {
    if (isClipboardSupported()) return '';

    let clipboard = '';

    try {
      clipboard = await navigator.clipboard.readText();
    } catch {
      showDismissableAlertToast(t('ClipboardError.title'), t('ClipboardError.description'), 'error');
    }

    return clipboard;
  };

  return {
    isClipboardSupported,
    isClipboardGranted,
    getClipboard,
  };
}
