import { graphql } from '@/lib/gql';

export const WalletConnectFormVaultFragment = graphql(`
  fragment WalletConnectFormVault on Vault {
    id
    name
    threshold
  }
`);

export const WalletConnectFormAddressFragment = graphql(`
  fragment WalletConnectFormAddress on Address {
    id
    balanceAsCoin: balance
    balanceUsd: balance(asFiat: USD)
    addressHash: publicKey
    asset {
      id
      createdAt
      symbol
      name
      rateUsd: rate(withFiat: USD)
      decimals
      ...AssetModel
      ...AssetIcon
    }
  }
`);

export const WalletConnectFormFragment = graphql(`
  fragment WalletConnectForm on Vault {
    id
    name
    balanceUsd: balance(asFiat: USD)
    threshold
    addresses: visibleAssets {
      id
      ...WalletConnectFormAddress
    }
    ...WalletConnectFormVault
  }
`);
