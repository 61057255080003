import styles from './FormActions.module.scss';

type Props = {
  alert?: React.ReactNode;
  cancel?: React.ReactNode;
  main: React.ReactNode;
};

export function FormActions({ alert = null, cancel = null, main }: Props) {
  return (
    <>
      {alert ? <div className={styles.alertWrapper}>{alert}</div> : null}

      <div className={styles.formActions}>
        {cancel}
        <div className={styles.mainActions}>{main}</div>
      </div>
    </>
  );
}
