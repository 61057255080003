'use client';
import { LinkInternal } from '@/components/Link';

type Props = {
  href: string;
  children: React.ReactNode;
};

export function LinkInternalWithPageReload({ children, href }: Props) {
  return (
    <LinkInternal href={href} variant="underline" onClick={handleOverrideLinkWithReload}>
      {children}
    </LinkInternal>
  );
}

function handleOverrideLinkWithReload(evt: React.MouseEvent<HTMLAnchorElement>) {
  evt.preventDefault();
  window.location.reload();
}
