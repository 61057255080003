'use client';
import { useTranslations } from 'next-intl';

import { Icons } from '@/lib/io-kit/Icons';
import { Button } from '@/lib/io-kit/Button';
import { useWalletConnectState } from '@/lib/wallet-connect/browser';
import { IconAsset } from '@/lib/io-kit/IconAssets';
import { Tooltip } from '@/lib/io-kit/Tooltip';

import styles from './Button.module.scss';

export default function WalletConnectButton() {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  const { isUserConnected, isLoading, mutations } = useWalletConnectState();

  const Icon = isLoading ? <Icons.Spinner spin /> : <IconAsset.WalletConnect />;

  const onClick = () => {
    mutations.toggleSidebar();
  };

  return (
    <Button
      className={styles.button}
      disabled={isLoading}
      data-testid="wallet-connect.login-button"
      variant="light"
      shape="pill"
      size="large"
      iconBefore={Icon}
      onClick={onClick}
    >
      WalletConnect
      {isUserConnected && (
        <div className={styles.connectionStatus}>
          <Icons.SuccessCircleFaded className={styles.checkIcon} />
          <span className={styles.status}>{t('connected')}</span>
        </div>
      )}
    </Button>
  );
}

export function WalletConnectButtonSkeleton() {
  return (
    <Button
      className={styles.button}
      disabled
      variant="light"
      shape="pill"
      size="large"
      iconBefore={<Icons.Spinner spin />}
    >
      WalletConnect
    </Button>
  );
}

export function WalletConnectButtonDisabled() {
  const t = useTranslations('Components.WalletConnect.WalletConnectForm');

  return (
    <Tooltip side="bottom" content={t('noVaultFound')}>
      <Button
        className={styles.button}
        disabled
        variant="light"
        shape="pill"
        size="large"
        iconBefore={<Icons.InfoCircle />}
      >
        WalletConnect
      </Button>
    </Tooltip>
  );
}
