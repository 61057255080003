'use client';

import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import cx from 'classnames';

import { Icons } from '@/lib/io-kit/Icons';

import styles from './Sheet.module.scss';

const Sheet = SheetPrimitive.Root;
const SheetTrigger = SheetPrimitive.Trigger;
const SheetClose = SheetPrimitive.Close;
const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ ...props }, ref) => <SheetPrimitive.Overlay {...props} ref={ref} className={styles.overlay} />);
SheetOverlay.displayName = 'SheetOverlay';

interface SheetContentProps extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content> {
  side?: 'top' | 'bottom' | 'left' | 'right';
}

const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
  ({ side = 'right', children, ...props }, ref) => (
    <SheetPortal>
      <SheetOverlay />
      <SheetPrimitive.Content {...props} ref={ref} className={cx(styles.content, styles[side])}>
        {children}
        <SheetClose className={styles.closeButton}>
          <Icons.CaretRight className={styles.closeIcon} />
        </SheetClose>
      </SheetPrimitive.Content>
    </SheetPortal>
  ),
);
SheetContent.displayName = 'SheetContent';

export { Sheet, SheetTrigger, SheetContent };
