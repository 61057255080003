'use client';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { LinkTo } from '@/lib/links';
import { UserInfo, UserPermissions } from '@/lib/auth';
import { IconName, Icons } from '@/lib/io-kit/Icons';
import { signOut } from '@/lib/auth/browser';
import { LinkInternal } from '@/components/Link';
import { UserAvatar } from '@/components/UserAvatar';

import styles from './AccountDropdown.module.scss';

type Props = { user: UserInfo };

export function AccountMenu({ user: userInfo }: Props) {
  const shouldShowSupport = UserPermissions.hasNetworkPM(userInfo) || UserPermissions.hasVault(userInfo);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={styles.dropdownTrigger} data-testid="account-nav.button">
        <UserAvatar user={userInfo} size="large" />

        <Icons.CaretDown aria-hidden="true" focusable="false" className={styles.dropdownIcon} />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={styles.dropdownContent}
          align="end"
          sideOffset={4}
          data-testid="account-nav.dropdown"
        >
          <LinkMenuItem to={LinkTo.profile()} iconName="User" label={'Profile'} data-testid="account-nav.profile" />
          {shouldShowSupport ? (
            <LinkMenuItem
              to={LinkTo.support()}
              iconName="InfoCircle"
              label={'Support'}
              data-testid="account-nav.support"
            />
          ) : null}
          <SignOutItem data-testid="account-nav.sign-out" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

function LinkMenuItem({
  to,
  iconName,
  label,
  'data-testid': testId,
}: {
  to: string;
  iconName: IconName;
  label: string;
  'data-testid': string;
}) {
  const Icon = Icons[iconName];

  return (
    <DropdownMenu.Item asChild>
      <LinkInternal variant="unstyled" href={to} className={styles.menuItem} data-testid={testId}>
        <Icon className={styles.menuItemIcon} />
        <span className={styles.menuItemLabel}>{label}</span>
      </LinkInternal>
    </DropdownMenu.Item>
  );
}

function SignOutItem({ 'data-testid': testId }: { 'data-testid': string }) {
  return (
    <DropdownMenu.Item className={styles.menuItem} onClick={signOut} data-testid={testId}>
      <Icons.SignOut className={styles.menuItemIcon} />
      <span className={styles.menuItemLabel}>Sign out</span>
    </DropdownMenu.Item>
  );
}
