'use client';

import Image from 'next/image';
import classNames from 'classnames';

import { LinkInternal } from '@/components/Link';

import styles from './LogoTopbar.module.scss';

export function LogoTopbar() {
  return (
    <LinkInternal variant="unstyled" href="/" className={styles.logoLink} aria-hidden="true">
      <div className={styles.logo}>
        <Image className={classNames(styles.logo)} src="/logo-iofinnet.svg" alt="" fill />
      </div>
    </LinkInternal>
  );
}
