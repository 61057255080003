'use client';
import { useCallback, useState } from 'react';
import * as RadixSheet from '@radix-ui/react-dialog';

import { IconButton } from '@/lib/io-kit/IconButton';

import styles from './MobileNav.module.scss';

const { Overlay, Root, Trigger, Close, Portal, Content } = RadixSheet;

type Props = {
  navMenu: React.ReactNode;
};

export function MobileNav({ navMenu }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Close the sheet when a child anchor element is clicked.
   * Using event propagation might be a bit brittle but it works for now.
   */
  const handleChildrenClick = useCallback((event: React.MouseEvent) => {
    if (event.target instanceof HTMLElement && event.target.closest('a')) {
      setIsOpen(false);
    }
  }, []);

  return (
    <Root open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <Trigger asChild>
        <span className={styles.buttonWrapper}>
          <IconButton as="button" variant="light" iconName="Hamburger" size="large" data-testid="mobile-nav.button" />
        </span>
      </Trigger>

      <Portal>
        <Overlay className={styles.sheetOverlay} />
        <Content className={styles.sheetContent} data-testid="mobile-nav">
          <div className={styles.container} onClick={handleChildrenClick}>
            <div className={styles.closeWrapper}>
              <Close asChild>
                <IconButton
                  as="button"
                  variant="light"
                  iconName="Close"
                  size="large"
                  data-testid="mobile-nav.close-button"
                />
              </Close>
            </div>
            <div>{navMenu}</div>
          </div>
        </Content>
      </Portal>
    </Root>
  );
}
