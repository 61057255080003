import Image from 'next/image';
import { useTranslations } from 'next-intl';

import { Heading } from '@/lib/io-kit/Heading';
import { Button } from '@/lib/io-kit/Button';
import { LinkInternal } from '@/components/Link';
import { LinkTo } from '@/lib/links';
import { DetailsCard } from '@/components/DetailsCard';

import styles from './NoVaultsEmptyState.module.scss';

export function NoVaultsEmptyState() {
  const t = useTranslations('Components.WalletConnect.NoVaultsEmptyState');

  return (
    <DetailsCard>
      <DetailsCard.Section
        className={styles.section}
        heading={
          <Heading as="h1" variant="heading4" className={styles.title}>
            {t('title')}
          </Heading>
        }
      >
        <div className={styles.container}>
          <div className={styles.iconWrapper}>
            <Image src="/vault-with-iofinnet-logo.svg" alt="" fill />
          </div>
          <div className={styles.content}>
            <p className={styles.subtitle}>{t('subtitle')}</p>
            <p className={styles.description}>{t('description')}</p>
          </div>
          <div className={styles.cta}>
            <LinkInternal variant="unstyled" href={LinkTo.vaultCreate()} passHref>
              <Button as="a" variant="primary" size="large" className={styles.button}>
                {t('cta')}
              </Button>
            </LinkInternal>
          </div>
        </div>
      </DetailsCard.Section>
    </DetailsCard>
  );
}
