import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.17.2_next@14.2.5_react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.17.2_next@14.2.5_react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1_sass@1.69.2/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1_sass@1.69.2/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["toast","dismiss"] */ "/vercel/path0/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.3.1_react@18.3.1/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Toasts/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkInternalWithPageReload"] */ "/vercel/path0/src/features/wallet-connect/manage-session/_components/LinkWithPageRefresh.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletConnectButtonSkeleton","WalletConnectButtonDisabled"] */ "/vercel/path0/src/features/wallet-connect/manage-session/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/features/wallet-connect/manage-session/LoginForm.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/features/wallet-connect/manage-session/page-logic/wc-form-logic.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/features/wallet-connect/manage-session/Tab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/features/wallet-connect/manage-session/UpdateForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccountMenu"] */ "/vercel/path0/src/layout/DashboardLayoutView/AccountDropdown.tsx.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/layout/DashboardLayoutView/index.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/layout/DashboardLayoutView/DashboardNav.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/vercel/path0/src/layout/DashboardLayoutView/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoTopbar"] */ "/vercel/path0/src/layout/DashboardLayoutView/LogoTopbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNav"] */ "/vercel/path0/src/layout/DashboardLayoutView/MobileNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageLink"] */ "/vercel/path0/src/layout/DashboardLayoutView/PageLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/auth/browser/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GqlBrowserProvider"] */ "/vercel/path0/src/lib/gql-client/browser/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/io-kit/Alert.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/io-kit/Icons/BaseIcon.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/io-kit/IconButton.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/layout/DashboardLayoutView/Topbar.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/layout/DashboardLayoutView/Sidebar.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ToastOnClientBlockOperationFinished"] */ "/vercel/path0/src/lib/subscriptions/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WalletConnectStateProvider","useWalletConnectState"] */ "/vercel/path0/src/lib/wallet-connect/browser/context/wallet-connect-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["reducer"] */ "/vercel/path0/src/lib/wallet-connect/browser/context/wallet-connect-state.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useWeb3Wallet"] */ "/vercel/path0/src/lib/wallet-connect/browser/hooks/useWeb3Wallet.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useWeb3WalletEvents"] */ "/vercel/path0/src/lib/wallet-connect/browser/hooks/useWeb3WalletEvents.ts");
